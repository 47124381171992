<template>
  <CContainer class="d-flex content-center min-vh-100">
    <CRow>
      <CCol>
        <CCardGroup>
          <CCard class="p-4">
            <CCardBody>
              <CForm @submit.prevent="login" method="POST">
                <h1>Login</h1>
                <p class="text-muted">Sign In to your account</p>
                <CInput v-model="email" prependHtml="<i class='cui-user'></i>" placeholder="Email" autocomplete="username email">
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput v-model="password" prependHtml="<i class='cui-lock-locked'></i>" placeholder="Password" type="password" autocomplete="curent-password">
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CRow>
                  <CCol col="6">
                    <CButton type="submit" color="primary" class="px-4">Login</CButton>
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton color="link" class="px-0">Forgot password?</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
          <CCard color="primary" text-color="white" class="text-center py-5 d-md-down-none" body-wrapper>
            <!--h2>Sign up</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <CButton color="primary" class="active mt-3" @click="goRegister()">
              Register Now!
            </CButton-->
            <div class="logo-container">
              <img class="logo-login" src="@/assets/images/global/logo.png" />
            </div>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<!--script lang="ts"-->
<script>
import { DEBUG } from '@/config/config'

export default {
  name: 'Login',
  computed: {
    debug() {
      return DEBUG
    }
  },
  data() {
    return {
      email: '',
      password: '',
      showMessage: false,
      message: ''
    }
  },
  created() {
    if (this.debug) document.body.classList.add('c-dark-theme')
  },
  methods: {
    /*goRegister() {
      this.$router.push({ path: 'register' })
    },*/
    login() {
      this.$store
        .dispatch('auth/login', { email: this.email, password: this.password })
        .then(response => {
          //console.log('Here', response)
          this.$router.push({ path: 'dashboard' })
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

<style lang="scss">
.logo-container {
  width: 200px;
  height: 200px;
  margin: 0 40px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  background-color: white;
  align-items: center;
}
.logo-login {
  width: 200px;
}
</style>
